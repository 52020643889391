import React, { useState, useEffect, useMemo } from "react";
import "./page.css";
// import {useLocation} from 'react-router-dom';
import Body from "./body";
import axios from "axios";
import { useCookies } from "react-cookie";
import Promo from "./Promo";
import Loginpg from "./Login";
import SLoader from "../Components/Loader/SigninLoader";
import TopSidebar from "../Layouts/Sidebar/TopSidebar";
import Sidebar from "../Layouts/Sidebar/Sidebar";
import Error from "../Components/Modal/Error";
import Points from "./points";
import Profile from "./Profile";
import { Store } from "../Redux/store";
import {
  claimsRecord,
  getAllRegions,
  getBankDetails,
  getExchangeRate,
  getPaymentOptions,
  getPaystackBanks,
  getPoints,
  getRegionPayment,
  getSeerbitsBanks,
  getUserData,
  seerbitPocketData,
  transactionRecord,
} from "../Redux/Actions/dataResponse";
import { NodeServer_API, paystack_API, Paystack_url } from "../server";
import { useSelector } from "react-redux";
import {
  captureSidebarClicks,
  claimsTabstatus,
  modalToggle,
  setsidebar,
} from "../Redux/Actions/buttonclicks";
import { paystackWalletBalance } from "../Redux/Actions/paystack";
import { findPaymentOption } from "../Utils/paymentoption";
import PaymentConfirmation from "../Components/PaymentConfirmation";
import Loader from "../Components/Loader/Loading";
import Support from "../Components/Support";
import Announcement from "../Components/Announcement/Announcement";
// import { jwtDecode } from "jwt-decode";

let setpaystackbanks = [];

const Dashboard = ({ currentpage }) => {
  // const [cookies] = useCookies(["Token"]);
  // Decode the token to extract the payload (the data inside the token)
  // const decodedToken = jwtDecode(cookies.Token);

  // Access the role and username from the decoded payload
  // const firstname = decodedToken.firstname;
  // const lastname = decodedToken.lastname;

  const [cookies, setCookie, removeCookie] = useCookies(["Token"]);
  const {
    bankdetails,
    userdata,
    regionlist,
    paymentoptionlist,
    transactionhistorydata,
    regionpaymentoption,
  } = useSelector((state) => state.datareponse);

  useEffect(() => {
    if (
      regionpaymentoption?.payment_mode === "ONLINE" &&
      (userdata.pcc === "" ||
        userdata.agentsign === "" ||
        userdata.agentsign === null ||
        bankdetails.length === 0)
    ) {
      Store.dispatch(setsidebar(false));
      Store.dispatch(claimsTabstatus(2));
    } else if (
      regionpaymentoption?.payment_mode === "OFFLINE" &&
      (userdata.pcc === "" ||
        userdata.agentsign === "" ||
        userdata.agentsign === null ||
        bankdetails.length === 0)
    ) {
      Store.dispatch(setsidebar(false));
      Store.dispatch(claimsTabstatus(2));
    } else {
      Store.dispatch(setsidebar(true));
    }
  }, [
    bankdetails?.length,
    regionpaymentoption?.payment_mode,
    userdata?.pcc,
    userdata?.agentsign,
  ]);

  // Frontend function to refresh token
  const refreshToken = async () => {
    try {
      const config = {
        method: "POST",
        url: `${NodeServer_API}/login/refresh-token`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.code === 200) {
          setCookie("Token", response.data.token, { path: "/" });
        }
      });
    } catch (error) {
      console.error("Failed to refresh token", error);
    }
  };

  // Detect user activity and reset token
  let inactivityTimeout;
  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout); // Clear the previous timeout

    inactivityTimeout = setTimeout(() => {
      console.log("No activity detected for a while. Refreshing token...");
      refreshToken(); // Refresh token or any other action
    }, 5 * 60 * 1000); // Wait for 5 minuites before Refreshing token due to inactivity
  };

  // Attach event listeners to detect activity
  useEffect(() => {
    window.addEventListener("mousemove", resetInactivityTimeout);
    window.addEventListener("keydown", resetInactivityTimeout);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", resetInactivityTimeout);
      window.removeEventListener("keydown", resetInactivityTimeout);
    };
  }, []);

  // Logout due to inactivity (frontend)
  const logoutDueToInactivity = () => {
    removeCookie("Token", { path: "/" }); // Remove the token cookie
    window.location.href = "/login"; // Redirect to login page
    // alert("You have been logged out due to inactivity.");
  };

  // Set inactivity timer for session timeout
  let sessionTimeout;
  const setSessionTimeout = () => {
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(logoutDueToInactivity, 25 * 60 * 1000); // 15 minutes of inactivity
  };

  // Attach event listeners to reset session timeout
  useEffect(() => {
    window.addEventListener("mousemove", setSessionTimeout);
    window.addEventListener("keydown", setSessionTimeout);

    // Initialize timeout on page load
    setSessionTimeout();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(sessionTimeout);
      window.removeEventListener("mousemove", setSessionTimeout);
      window.removeEventListener("keydown", setSessionTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { paystackWalletBalance } = useSelector((state) => state.paystackdata);

  const filteredObject = useMemo(() => {
    return findPaymentOption(paymentoptionlist, userdata, regionlist);
  }, [paymentoptionlist, userdata, regionlist]);

  const { newmodal, sidebartab, togglemodal, isLoading } = useSelector(
    (state) => state.buttonclicks
  );

  // console.log("Obaloluwa: ", newmodal?.status);

  const [params, setParams] = useState("");

  const [changedstatus, setChangedstatus] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(true);
  const [pagestatus, setPagestatus] = useState("");
  const [successful, setSuccessful] = useState({
    statuscode: 0,
    message: "",
    type: "",
  });

  const [responseMessage, setResponseMessage] = useState({
    loadStatus: 0,
    loadmessage: "",
    icon: "",
  });

  useEffect(() => {
    if (filteredObject && Object.keys(filteredObject).length > 0) {
      Store.dispatch(getRegionPayment(filteredObject));
    }
  }, [filteredObject]);

  useEffect(() => {
    if (transactionhistorydata?.length > 0) {
      Store.dispatch(modalToggle("paynotice"));
    }
  }, [transactionhistorydata?.length]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    currentpage(
      bankdetails.length !== 0 &&
        userdata.pcc !== "" &&
        (userdata.agentsign !== "" || userdata.agentsign !== null)
        ? 1
        : 2
    );

    fetchTransactionHistory();
    Makerolecall();
    FetchRegions();
    FetchPaymentOptions();
    FetchAvailableBanks();
    FetchPaystackBalance();
    FetchprimaryPockets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedstatus]);

  useEffect(() => {
    handleClicked(sidebartab);
  }, [sidebartab]);

  useEffect(() => {
    getPaystackbanks(`${paystack_API}${params}`);
  }, [params, paystack_API]);

  const fetchTransactionHistory = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/transactionHistory`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(transactionRecord(response.data.data));
        }
      });
    } catch (e) {
      // Error
    }
  };

  const FetchRegions = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/region`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getAllRegions(response.data.data));
        }
      });
    } catch (e) {
      // Error
    }
  };

  const FetchPaymentOptions = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/payment`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getPaymentOptions(response.data.data));
        }
      });
    } catch (e) {
      // Error
    }
  };

  const FetchprimaryPockets = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/seerbit/fetchprimarypockets`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(seerbitPocketData(response.data.data));
        }
      });
    } catch (e) {
      // toast.error(e.response.data);
    }
  };

  const FetchPaystackBalance = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/balance`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PS_PUBLIC_LIVE_KEY,
        },
      };

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(paystackWalletBalance(response.data.data[0].balance));
        }
      });
    } catch (e) {
      console.log(e.response.data);
    }
  };

  const FetchAvailableBanks = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/seerbit/providers`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getSeerbitsBanks(response.data.data));
        }
      });
    } catch (e) {
      // If response returns status of 401. then the session has expired, user have to re-login Session Time set to 1hr
      if (e.message.includes("401")) {
        setLoading(false);
      }
    }
  };

  const getPaystackbanks = async (urlendpoint) => {
    try {
      let config = {
        method: "GET",
        url: urlendpoint,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_PS_PUBLIC_LIVE_KEY,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.status === true) {
            setpaystackbanks.push(...response.data.data);

            if (response.data.meta.next !== null) {
              const nextUrl = `&next=${response.data.meta.next}`;
              setParams(nextUrl);
            } else {
              Store.dispatch(getPaystackBanks(setpaystackbanks));
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const Makerolecall = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/agents/home`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getUserData(response.data.data.user));

          Store.dispatch(getBankDetails(response.data.data.bankDetails));

          Store.dispatch(getExchangeRate(response.data.data.exchange_rate));

          Store.dispatch(getPoints(response.data.data.points));

          Store.dispatch(claimsRecord(response.data.data.claims));
          if (
            response.data.data.user.pcc === "" ||
            response.data.data.bankDetails.length === 0
          ) {
            setLoading(false);
            Store.dispatch(captureSidebarClicks("Profile"));
            setPage(7);
            setPagestatus("fresh load");
          } else {
            setLoading(false);
          }
        }
      });
    } catch (e) {
      setLoading(false);
      // setExpiration(true)
      if (e.response.status === 401) {
        handleNotice({
          // Expired Session
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      } else {
        handleNotice({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const handleClicked = (props) => {
    if (props === "Main") {
      setPage(1);
    }

    if (props === "Points") {
      setPage(2);
      Store.dispatch(claimsTabstatus(1));
    }

    if (props === "Profile") {
      setPage(7);
      Store.dispatch(claimsTabstatus(2));
      setPagestatus("not fresh load");
    }

    if (props === "Message") {
      setPage(4);
    }

    if (props === "Promo") {
      setPage(3);
    }
  };

  const menubarClick = () => {
    setMenu(!menu);
  };

  const handleState = (stateStatus) => {
    setChangedstatus(stateStatus);
  };

  const handleClose = () => {
    // setLoadoption(0);
    setResponseMessage({ ...responseMessage, loadStatus: 0 });
  };

  const handleProfileSuccess = (incomingObject) => {
    setResponseMessage({
      ...responseMessage,
      loadStatus: incomingObject.loadstatus,
      loadmessage: incomingObject.loadMessage,
      responseicon: incomingObject.icon,
    });
  };

  const handleNotice = (props) => {
    setSuccessful({ successful, ...props });
  };

  return (
    <>
      {loading ? (
        <SLoader loggedin={1} type="bars" text="Fetching Data" />
      ) : (
        <div className="bnwd-container">
          {togglemodal === "supporticket" && <Support />}
          {transactionhistorydata?.filter(
            (tdata) =>
              tdata.user_id === userdata.ID &&
              (tdata.status === "Successful" || tdata.status === "Pending") &&
              tdata.recipient_status === 0
          )?.length > 0 &&
            togglemodal === "paynotice" && <PaymentConfirmation />}

          <TopSidebar menuClicked={menubarClick} />

          <div className="bnw-botm-container">
            <Sidebar
              changeWid={menubarClick}
              pageStatus={pagestatus}
              changeWidth={menu}
            />
            {page === 1 ? (
              <Body />
            ) : page === 2 ? (
              <Points
                // setreat={TreatedClick}
                changed={handleState}
                respmessage={handleProfileSuccess}
              />
            ) : page === 3 ? (
              <Promo />
            ) : page === 7 ? (
              <Profile respmessage={handleProfileSuccess} />
            ) : (
              <Loginpg />
            )}
          </div>

          {responseMessage.loadStatus === 1 ? (
            <SLoader
              loggedin={2}
              type="spin"
              text={responseMessage.loadmessage}
            />
          ) : responseMessage.loadStatus === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={responseMessage.loadmessage}
              isTimeout={false}
              icon={responseMessage.responseicon}
            />
          ) : (
            <></>
          )}

          {newmodal?.status === 1 && <Announcement notice={newmodal} />}

          {isLoading.status && (
            <div className="flex-1 w-full fixed top-0 h-screen items-center justify-center flex z-30 bg-[#0000006d]">
              <Loader type={isLoading.type} text={isLoading.text} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
