import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
// import { Store } from "../../Redux/store";
// import { captureModalOverlay } from "../../Redux/Actions/buttonClick";
// import { useSelector } from "react-redux";
import { IoWarningOutline } from "react-icons/io5";
import { captureModalOverlay } from "../../Redux/Actions/buttonclicks";
import { Store } from "../../Redux/store";

const Announcement = ({ notice }) => {
  // console.log(notice);
  const handleClose = () => {
    Store.dispatch(
      captureModalOverlay({
        status: 0,
        value: "",
      })
    );
  };

  return (
    <div class="w-full h-full bg-black/20 fixed top-0 flex items-center justify-center z-10">
      <div className="min-w-[260px] w-full max-w-[500px] min-h-[250px] bg-[white] flex flex-col gap-3 p-3 px-5">
        <div className="flex w-full justify-end items-center relative mb-1">
          <IoCloseCircleOutline
            size={30}
            className="cursor-pointer"
            onClick={() => handleClose()}
          />
        </div>

        <div className="w-full flex flex-col gap-4 items-center pb-3">
          <IoWarningOutline size={60} color="orange" />
          <span
            className="text-[15px] font-normal text-left"
            dangerouslySetInnerHTML={{ __html: notice.value }}
          />
        </div>
      </div>
    </div>
  );
};

export default Announcement;
