import React, { useState, useEffect } from "react";
import Singleselect from "../Components/Singleselect/Singleselectt";
import "./page.css";
import axios from "axios";
import { useCookies } from "react-cookie";
// import { bankList, mobileMoney } from "./banks";
import Radiofield from "../Components/Radiofield/Radiofield";
import Bankdropdown from "../Components/Singleselect/Bank";
import RegionsJson from "./Registerpage/RegionJson";
import { MdContactPhone } from "react-icons/md";
import { FaCheck, FaChevronDown } from "react-icons/fa";
import dailingCode from "./Registerpage/dailingcode";
import { useSelector } from "react-redux";
import { IoCloseCircleOutline, IoWarningOutline } from "react-icons/io5";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { Store } from "../Redux/store";
import { getBankDetails, getUserData } from "../Redux/Actions/dataResponse";
import { NodeServer_API } from "../server";
import { useTranslation } from "react-i18next";

const demoaccount = {
  ID: 1,
  accountname: "John Doe",
  bankname: "xxxx xxxxxx xxxx",
  accountnumb: "1234567812",
};

const Profile = ({ respmessage }) => {
  const { t } = useTranslation();
  const {
    userdata,
    paystackbankdata,
    seerbitprovider,
    bankdetails,
    regionpaymentoption,
  } = useSelector((state) => state.datareponse);

  let users = userdata;
  let accountdetails = bankdetails;

  const [cookies] = useCookies(["Token"]);
  const [isverified, setIsverified] = useState(false);
  const [tab, setTab] = useState(1);
  const [deactivate, setDeactivate] = useState(false);
  const [regionsjson, setRegionsjson] = useState([]);
  const [seewarning, setSeewarning] = useState(true);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [bank, setBank] = useState({
    bankname: "",
    bankcode: "",
    recipient_code: "",
  });

  useEffect(() => {
    if (bankdetails.length >= 1) {
      setDeactivate(true);
    } else {
      setDeactivate(false);
    }
  }, [bankdetails.length]);

  const [accountype, setAccountype] = useState({
    bank: true,
    mobilemoney: false,
    cashtransfer: false,
  });

  // const [pccstatus, setPccstatus] = useState(1)

  const [details, setDetails] = useState({
    booknwincode: users.promo_code,
    accountemail: users.email,
    fname: users.first_name,
    mname: users.middle_name,
    lname: users.last_name,
    dailcode: users.dailcode,
    mobile:
      users?.phone?.indexOf("+") === -1
        ? users?.phone
        : users?.phone?.substring(4),
    agencyname: users.agency_name,
    regioncode: users.region_code,
    confpassword: "",
    password: "",
    currpassword: "",
    pcc: users.pcc,
    agentsign: users.agentsign,
    accountname: "", // Holders name
    walletname: "", // Bank/Momo
    accountnumber: "", // Account/Mobile Number
    pccupdatestatus: users.pcc_change_count,
  });

  const [selectedCode, setSelectedCode] = useState();

  useEffect(() => {
    // Find the dialing code by the fetched id (dailcode)
    const selectedDailingCode = dailingCode.find(
      (item) => item.id === users.dailcode
    );

    if (selectedDailingCode) {
      setSelectedCode(selectedDailingCode.code);
    }
  }, [users.dailcode]);

  const handleTabChange = (e) => {
    if (e.target.id === "tab1") {
      setTab(1);
    }

    if (e.target.id === "tab2") {
      setTab(2);
      setSeewarning(true);
      setIsverified(false);
      setLoading(false);
      setDetails({ ...details, accountnumber: "" });
    }

    if (e.target.id === "tab3") {
      setTab(3);
    }
  };

  // On load of this page, load all available regions
  useEffect(() => {
    LoadAvailableRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoadAvailableRegions = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/regions`,
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setRegionsjson(response.data.data);
        }
      });
    } catch (e) {
      //error
    }
  };

  // On Load of the page, if the active region is NG, show only Bank transfer as the payment option
  useEffect(() => {
    if (users.region_code !== "NG") {
      setAccountype({ bank: false, mobilemoney: true, cashtransfer: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to handle empty object
  const isEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0;
  };

  const providerDetail =
    (users.region_code !== "NG" &&
      !isEmpty(seerbitprovider) &&
      seerbitprovider[
        RegionsJson?.find((item) => item.value === users?.region_code)?.name
      ]?.map((detailObj, index) => {
        return { momoname: detailObj, id: index + 1 };
      })) ||
    [];

  // Function that handles changing the different mdoe of payment, pay via BANK, MOBILE MONEY A& CASH RECIEPT
  const onChangeAccount = (e) => {
    const { name } = e.target;
    if (name === "bank") {
      setAccountype({ bank: true, mobilemoney: false, cashtransfer: false });
    }
    if (name === "mobilemoney") {
      setAccountype({ bank: false, mobilemoney: true, cashtransfer: false });
    }
    if (name === "cashtransfer") {
      setAccountype({ bank: false, mobilemoney: false, cashtransfer: true });
    }
  };

  // Function to update user profile
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Loading
      respmessage({
        loadstatus: 1,
        loadMessage: "Uploading Record ...",
        icon: "0",
      });

      const obj = {
        region_code: country,
        first_name: details.fname,
        middle_name: details.mname,
        last_name: details.lname,
        phone: details.mobile,
        agency_name: details.agencyname,
        email: details.accountemail,
        dailcode: details.dailcode,
        agentsign: details.agentsign,
        pcc: details.pcc.toUpperCase(),
        dob: users.dob,
        gender: users.gender,
      };

      var config = {
        method: "POST",
        url: `${NodeServer_API}/updateProfile`,
        data: obj,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      if (obj.pcc !== "") {
        await axios(config).then((response) => {
          if (response.data.success === true) {
            respmessage({
              loadstatus: 0,
              loadMessage: "",
              icon: "0",
            });

            toast.success("Profile Successfully Updated");
            Store.dispatch(getUserData(response.data.data[0]));
          }
        });
      }
    } catch (e) {
      respmessage({
        loadstatus: 0,
        loadMessage: "",
        icon: "0",
      });

      if (e.code === "ERR_NETWORK") {
        toast.error("Newtork Error");
      } else if (
        e.message.includes("500") ||
        e.message.includes("502") ||
        e.message.includes("503") ||
        e.message.includes("504")
      ) {
        toast.error("Service Unavailable/Internal Server Error");
      } else if (e.message.includes("400") || e.message.includes("404")) {
        toast.error("Bad Request/Unauthorized");
        // Bad response 400
      }
    }
  };

  // Function to handle payment via Paystack
  const handlePassSubmit = () => {
    // const obj = {
    //     confpassword: details.confpassword,
    //     password: details.password
    // }
  };

  // Function to add New Account information
  const handleAccountSubmit = async (e) => {
    try {
      e.preventDefault();

      // Loading
      respmessage({
        loadstatus: 1,
        loadMessage: "Uploading Record ...",
        icon: "0",
      });

      // const obj = {
      //   user_id: userdata?.ID,
      //   paymentMethod: accountype.cashtransfer ? 2 : accountype.bank ? 1 : 0,
      //   accountNumber:
      //     regionpaymentoption?.payment_mode === "ONLINE"
      //       ? details.accountnumber
      //       : "OFFLINE",
      //   bankName:
      //     regionpaymentoption?.payment_mode === "ONLINE"
      //       ? bank.bankname
      //       : "OFFLINE",
      //   recipient_code: users.region_code === "NG" ? bank.recipient_code : "",
      //   users,
      // };

      const obj = {
        user_id: users?.ID,
        paymentMethod:
          regionpaymentoption?.payment_mode === "OFFLINE"
            ? 2
            : accountype.bank
            ? 1
            : accountype.mobilemoney && 0,
        accountNumber: details.accountnumber,
        bankName: bank.bankname,
        recipient_code: users.region_code === "NG" ? bank.recipient_code : "",
        users,
      };

      var config = {
        method: "POST",
        url: `${NodeServer_API}/agents/storeBankDetails`,
        data: obj,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      if (
        obj.accountNumber !== "" &&
        obj.bankName !== "" &&
        obj.user_id !== "" &&
        obj.user_id !== undefined
      ) {
        await axios(config).then((response) => {
          if (response.data.success === true) {
            toast.success("Account Successfully Updated");
            setIsverified(false);
            Store.dispatch(getBankDetails(response.data.data));

            respmessage({
              loadstatus: 0,
              loadMessage: "",
              icon: "0",
            });
          }
        });
      } else {
        toast.error("Important Fields not filled!");

        respmessage({
          loadstatus: 0,
          loadMessage: "",
          icon: "0",
        });
      }
    } catch (e) {
      toast.error(e.response.data.message);
      respmessage({
        loadstatus: 0,
        loadMessage: "",
        icon: "0",
      });
    }
  };

  // verify paystack account, after verification, the button to update account is enabled
  const enableAccountVerification = async (e) => {
    e.preventDefault();

    const obj = {
      type: "nuban",
      name: users.first_name.toUpperCase(),
      account_number: details.accountnumber,
      bank_code: bank.bankcode,
      currency: "NGN",
    };

    if (obj.account_number.length === 10 && obj.bank_code !== "") {
      setLoading(true);
      try {
        var config = {
          method: "POST",
          url: `https://api.paystack.co/transferrecipient`,
          data: obj,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + process.env.REACT_APP_PS_PUBLIC_LIVE_KEY,
          },
        };

        await axios(config).then((response) => {
          if (response.data.status === true) {
            let namelist = response.data.data.details.account_name.split(" ");
            let nameresponse = namelist.map((item) => {
              return item === response.data.data.name;
            });

            if (nameresponse.includes(true)) {
              setBank({
                ...bank,
                recipient_code: response.data.data.recipient_code,
              });
              setLoading(false);
              setIsverified(true);
            } else {
              toast.error("Accounts name does not match");
              setLoading(false);
            }
          }
        });
      } catch (e) {
        setLoading(false);
        toast.error(e.response.data.message);
      }
    } else {
      setLoading(false);
      toast.error("Ensure all fields are filled!");
    }
  };

  const disableAccountVerification = () => {
    //Do Nothing
  };

  // function that handles text change in the update userprofile
  const handleChange = (event) => {
    setIsverified(false);

    const { name, value } = event.target;
    const regex = /^[0-9]+$/;

    if (name === "accountnumber" || name === "mobile") {
      if (event.target.value === "" || regex.test(event.target.value)) {
        // Check if the field contains number only
        if (event.target.value.length <= 15) {
          setDetails({ ...details, [name]: value });
        }
      }
    } else if (
      name === "accountname" ||
      name === "agencyname" ||
      name === "country" ||
      name === "fname" ||
      name === "mname" ||
      name === "lname" ||
      name === "confpassword" ||
      name === "password" ||
      name === "currpassword" ||
      name === "agentsign"
    ) {
      setDetails({ ...details, [name]: value });
    } else {
      setDetails({ ...details, [name]: value });
    }
  };

  // function that handles selection/changing of country or region
  const handleSelected = (incoming) => {
    setCountry(incoming);
  };

  // This function handles change of banks/accounts
  const handleAccountSelected = (incoming) => {
    setIsverified(false);
    setBank({
      ...bank,
      bankname: incoming.soc,
      bankcode: incoming.rn,
      recipient_code: incoming.recieptcode,
    });
  };

  const [social, setSocial] = useState(false);

  const handleSocialFocus = () => {
    setSocial(!social);
  };

  const handleDailCode = (e, item) => {
    setSelectedCode(item.code);
    setDetails({ ...details, dailcode: item.id });
  };

  // Check if the region code is "NG" or present in provider data
  const isRegionInProviderData = (regionCode) => {
    // Find the region name corresponding to the region code
    const region = RegionsJson.find((r) => r.value === regionCode);
    if (!region) return false;

    // Check if the region name is present in provider data
    return seerbitprovider.hasOwnProperty(region.name);
  };

  const handleSelectBank = (e) => {
    setBank({ ...bank, bankname: e.target.value });
  };

  // console.log(regionpaymentoption);

  return (
    <div className="bnw-promopg-container">
      <div className="booknwin-profile-container">
        <div className="bnw-claim-client-tab-container">
          <div
            id="tab1"
            className={tab === 1 ? "active" : ""}
            onClick={handleTabChange}
          >
            {t("profiledetails")}
          </div>

          {/* if payment mode is offline, don't display this, however, show it if it's Online */}
          <div
            id="tab2"
            className={tab === 2 ? "active" : ""}
            onClick={handleTabChange}
          >
            {t("accountinfo")}
          </div>
        </div>

        {tab === 1 ? (
          <form onSubmit={handleSubmit} className="register-new-user">
            <div className="account-information">
              <table>
                <thead>
                  <tr>
                    <th scope="col">{t("email")}</th>
                    <th scope="col">{t("bawcode")}</th>
                    <th scope="col">{t("country")}</th>
                    <th scope="col">{t("firstname")}</th>
                    <th scope="col">{t("middlename")}</th>
                    <th scope="col">{t("lastname")}</th>
                    <th scope="col">{t("phone")}</th>
                    <th scope="col">{t("agencyname")}</th>
                    <th scope="col">{t("bookpcc")}</th>
                    <th scope="col">{t("agentsign")}</th>
                  </tr>
                </thead>

                <tbody>
                  {[details].flat().map((items, index) => (
                    <tr key={items.index}>
                      <td data-label={t("email")}>{items.accountemail}</td>
                      <td data-label={t("bawcode")}>{items.booknwincode}</td>
                      <td data-label={t("country")}>
                        {
                          RegionsJson?.find(
                            (regionname) =>
                              regionname.value === items.regioncode
                          ).name
                        }
                      </td>
                      <td data-label={t("firstname")}>{items.fname}</td>
                      <td data-label={t("middlename")}>{items.mname}</td>
                      <td data-label={t("lastname")}>{items.lname}</td>
                      <td data-label={t("phone")}>{items.mobile}</td>
                      <td data-label={t("agencyname")}>{items.agencyname}</td>
                      <td data-label={t("bookpcc")}>{items.pcc}</td>
                      <td data-label={t("agentsign")}>{items.agentsign}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("email")} :</span>
              </div>
              <div>
                <input
                  value={details.accountemail}
                  type="text"
                  id="reg-text-field"
                  disabled
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("bawcode")} :</span>
              </div>
              <div>
                <input
                  value={details.booknwincode}
                  type="text"
                  id="reg-text-field"
                  disabled
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("country")} :</span>
              </div>

              <div>
                <Singleselect
                  selectedSocVal={handleSelected}
                  socialjs={regionsjson}
                  placeholder="Region"
                />
                {/* <input name='country' value={details.country} onChange={handleChange} type="text" id='reg-text-field'/> */}
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("firstname")} :</span>
              </div>
              <div>
                <input
                  name="fname"
                  value={details.fname}
                  onChange={handleChange}
                  type="text"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("middlename")} :</span>
              </div>
              <div>
                <input
                  name="mname"
                  value={details.mname === "None" ? "" : details.mname}
                  onChange={handleChange}
                  type="text"
                  id="reg-text-field"
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("lastname")} :</span>
              </div>
              <div>
                <input
                  name="lname"
                  value={details.lname === "None" ? "" : details.lname}
                  onChange={handleChange}
                  type="text"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            {/* <div className='register-new-user-tab'>
                    <div><span>Mobile :</span></div>
                    <div>
                        <input name='mobile' placeholder="+2341234345678" value={details.mobile} onChange={handleChange} type="tel" id='reg-text-field' required/>
                    </div>
                </div> */}

            <div className="register-new-user-tab">
              <div>
                <span>{t("phone")} :</span>
              </div>
              <div className="bnw-reg-phone-container">
                <div className="dailcode-select-container">
                  <input
                    readOnly={true}
                    required
                    value={selectedCode}
                    onFocus={handleSocialFocus}
                    id="reg-phone-drpdwn"
                  />
                  <FaChevronDown
                    id="tel-chevron-down"
                    onClick={handleSocialFocus}
                  />
                  {social && (
                    <div
                      onMouseLeave={handleSocialFocus}
                      className="bnw-dailer-drpdwn"
                    >
                      {dailingCode.map((item, key) => (
                        <span
                          onClick={(e) => handleDailCode(e, item)}
                          key={key}
                        >
                          {item.code}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  id="reg-phone-field"
                  name="mobile"
                  placeholder="80123549586"
                  value={details.mobile}
                  type="tel"
                  onChange={handleChange}
                  required
                />
                {<MdContactPhone id="visib-icons" />}
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>{t("agencyname")} :</span>
              </div>
              <div>
                <input
                  disabled
                  name="agencyname"
                  value={details.agencyname}
                  onChange={handleChange}
                  type="text"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>Booking PCC :</span>
              </div>
              <div>
                <input
                  disabled={userdata.pcc !== "" ? true : false}
                  name="pcc"
                  value={details.pcc}
                  onChange={handleChange}
                  type="text"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>Booking Agent Sign :</span>
              </div>
              <div>
                <input
                  disabled={
                    userdata.agentsign !== null && userdata.agentsign !== ""
                      ? true
                      : false
                  }
                  name="agentsign"
                  value={details.agentsign}
                  onChange={handleChange}
                  type="text"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div></div>
              <div>
                <input
                  type="submit"
                  value={t("updateprofile")}
                  id="reg-submit-field"
                  className="rounded-[5px] text-[14px]"
                  required
                />
              </div>
            </div>
          </form>
        ) : tab === 2 ? (
          <div className="register-new-user gap-2">
            <div className="w-full flex items-center justify-center">
              {seewarning && users.region_code === "NG" && (
                <div className="w-fit bg-[orange] text-center flex items-center justify-center gap-2 text-[white] p-2 rounded-[10px] relative">
                  <IoWarningOutline size={18} color="white" />
                  <span className="text-[13px]">{t("prompt")}</span>

                  <IoCloseCircleOutline
                    size={20}
                    color="white"
                    className="cursor-pointer"
                    onClick={() => setSeewarning(false)}
                  />
                </div>
              )}
            </div>

            <div className="account-information">
              <table>
                <thead>
                  <tr>
                    <th scope="col">SN</th>
                    <th scope="col">{t("bankname")}</th>
                    <th scope="col">{t("accountname")}</th>
                    <th scope="col">{t("accountnumber")}</th>
                  </tr>
                </thead>

                <tbody>
                  {[accountdetails === undefined ? demoaccount : accountdetails]
                    .flat()
                    .map((items, index) => (
                      <tr key={index}>
                        <td data-label="S/N">{index + 1}</td>
                        <td data-label={t("bankname")}>{items.bankName}</td>
                        <td data-label={t("accountname")}>
                          {users.first_name + " " + users.last_name}
                        </td>
                        <td data-label={t("accountnumber")}>
                          {items.accountNumber}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="flex w-full gap-3 mb-3">
              {/* if the logged in user is from NG show bank account select */}
              {regionpaymentoption?.payment_mode === "ONLINE" &&
                (regionpaymentoption?.paymentprovider_id === 2 ||
                  regionpaymentoption?.paymentprovider_id === 3) && (
                  <Radiofield
                    name="bank"
                    id="bank"
                    value="bank"
                    onChange={onChangeAccount}
                    checked={accountype.bank}
                    text={t("bankaccount")}
                  />
                )}

              {/* if the logged in user is not from NG show bank account select */}
              {/* // Seerbit payment option */}

              {regionpaymentoption?.payment_mode === "ONLINE" &&
                regionpaymentoption?.paymentprovider_id === 1 &&
                isRegionInProviderData(users.region_code) && (
                  <Radiofield
                    name="mobilemoney"
                    id="mobilemoney"
                    value="mobilemoney"
                    onChange={onChangeAccount}
                    checked={accountype.mobilemoney}
                    text={t("mobilewallet")}
                  />
                )}

              {/* // Paystack payment option */}
              {regionpaymentoption?.payment_mode === "OFFLINE" && (
                <Radiofield
                  name="cashtransfer"
                  id="cashtransfer"
                  value="cashtransfer"
                  onChange={onChangeAccount}
                  checked={accountype.cashtransfer}
                  text={t("cashtransfer")}
                />
              )}
            </div>

            {regionpaymentoption?.payment_mode === "ONLINE" && (
              <form
                onSubmit={handleAccountSubmit}
                className="flex gap-2 flex-col"
              >
                <div className="flex w-full items-center micro_screen:flex-col micro_screen:items-start">
                  <div className="text-[14px] w-[160px] font-bold">
                    {regionpaymentoption?.paymentprovider_id === 1 &&
                    users.region_code !== "NG" ? (
                      <span>{t("mobilenumber")} :</span>
                    ) : regionpaymentoption?.paymentprovider_id === 1 &&
                      users.region_code === "NG" ? (
                      <span>{t("accountnumber")}:</span>
                    ) : (
                      regionpaymentoption?.paymentprovider_id !== 1 && (
                        <span>{t("accountnumber")}:</span>
                      )
                    )}
                  </div>

                  <div className="flex w-[calc(100%-160px)] min-w-[190px] micro_screen:w-full">
                    <input
                      disabled={accountdetails.length >= 1 ? true : false}
                      name="accountnumber"
                      value={details.accountnumber}
                      onChange={handleChange}
                      type="text"
                      className="account_number_field w-[70%] min-w-[190px] py-[5px] px-[10px] outline-none text-[13px] font-[500] phone_screen:w-full"
                      required
                    />
                  </div>
                </div>

                <div className="flex w-full items-center micro_screen:flex-col micro_screen:items-start">
                  <div className="text-[14px] w-[160px] font-bold">
                    {regionpaymentoption?.paymentprovider_id === 1 &&
                    users.region_code !== "NG" ? (
                      <span>{t("mobilemoney")} </span>
                    ) : (
                      <span>{t("bank")} :</span>
                    )}
                  </div>

                  <div className="flex w-[calc(100%-160px)] min-w-[190px] micro_screen:w-full">
                    <Bankdropdown
                      selectedSocVal={handleAccountSelected}
                      // this is where you pass the payment provider bank list data
                      socialjs={
                        regionpaymentoption?.paymentprovider_id === 1 &&
                        users.region_code !== "NG"
                          ? providerDetail
                          : paystackbankdata
                      }
                      placeholder={
                        regionpaymentoption?.paymentprovider_id === 1 &&
                        users.region_code !== "NG"
                          ? "Select Mobile Money"
                          : "Select Bank"
                      }
                    />

                    {users.region_code === "NG" && (
                      <div
                        // disabled={true}
                        className={`flex justify-center cursor-pointer items-center ${
                          loading || deactivate ? "bg-[#888]" : "bg-[green]"
                        } p-2 ml-2 relative`}
                        onClick={
                          deactivate
                            ? disableAccountVerification
                            : enableAccountVerification
                        }
                      >
                        {isverified ? (
                          <FaCheck size={15} color="white" />
                        ) : (
                          <span className="text-[13px] font-[500] text-[white]">
                            {t("verify")}
                          </span>
                        )}

                        {loading && (
                          <ReactLoading
                            className="absolute left-[25%] top-[20%]"
                            type="spin"
                            color="white"
                            height={"50%"}
                            width={"50%"}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex w-full items-center micro_screen:flex-col micro_screen:items-start">
                  <div className="text-[14px] w-[160px] font-bold" />

                  <div className="flex w-[calc(100%-160px)] min-w-[190px micro_screen:w-full">
                    {users.region_code === "NG" ? (
                      <input
                        disabled={
                          users.region_code === "NG" &&
                          (!isverified || accountdetails.length === 1)
                        }
                        type="submit"
                        value={t("updateaccount")}
                        className={`w-[70%] text-[white] min-w-[190px] py-[10px] px-[10px] outline-none text-[13px] font-[600] ${
                          users.region_code === "NG" &&
                          (!isverified || accountdetails.length === 1)
                            ? "bg-[#888]"
                            : "bg-[#e50000]"
                        } text-[14px] phone_screen:w-full cursor-pointer rounded-[5px]`}
                      />
                    ) : (
                      <input
                        disabled={accountdetails.length === 1}
                        type="submit"
                        value={t("updateaccount")}
                        className={`w-[70%] text-[white] min-w-[190px] py-[10px] px-[10px] outline-none text-[13px] font-[600] ${
                          accountdetails.length === 1
                            ? "bg-[#888]"
                            : "bg-[#e50000]"
                        } text-[14px] phone_screen:w-full cursor-pointer rounded-[5px]`}
                      />
                    )}
                  </div>
                </div>
              </form>
            )}

            {regionpaymentoption?.payment_mode === "OFFLINE" && (
              <form onSubmit={handleAccountSubmit}>
                <div className="flex w-full gap-2 flex-col">
                  <div className="text-[13px]">{t("offlinetext")}</div>
                  <div className="flex flex-col gap-2 flex-1">
                    <div className="flex w-full items-center micro_screen:flex-col micro_screen:items-start">
                      <div className="text-[14px] w-[160px] font-bold">
                        <span>{t("accountnumber")} :</span>
                      </div>

                      <div className="flex w-[calc(100%-160px)] min-w-[190px] micro_screen:w-full">
                        <input
                          disabled={accountdetails.length >= 1 ? true : false}
                          name="accountnumber"
                          value={details.accountnumber}
                          onChange={handleChange}
                          type="text"
                          className="account_number_field w-[70%] min-w-[190px] py-[5px] px-[10px] outline-none text-[13px] font-[500] phone_screen:w-full"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex w-full items-center micro_screen:flex-col micro_screen:items-start">
                      <div className="text-[14px] w-[160px] font-bold">
                        <span>{t("bank")} :</span>
                      </div>

                      <div className="flex w-[calc(100%-160px)] min-w-[190px] micro_screen:w-full">
                        <input
                          disabled={accountdetails.length >= 1 ? true : false}
                          name="bankname"
                          value={bank.bankname}
                          onChange={handleSelectBank}
                          type="text"
                          className="account_number_field w-[70%] min-w-[190px] py-[5px] px-[10px] outline-none text-[13px] font-[500] phone_screen:w-full"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex w-full items-center micro_screen:flex-col micro_screen:items-start">
                      <div className="text-[14px] w-[160px] font-bold"></div>

                      <div className="flex w-[calc(100%-160px)] min-w-[190px] micro_screen:w-full">
                        <input
                          disabled={accountdetails.length >= 1 ? true : false}
                          type="submit"
                          value={t("updateaccount")}
                          className={`w-[70%] text-[white] min-w-[190px] py-[10px] px-[10px] outline-none text-[14px] font-[600] ${
                            accountdetails.length >= 1
                              ? "bg-[#888]"
                              : "bg-[#e50000]"
                          } text-[13px] phone_screen:w-full cursor-pointer rounded-[5px]`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div className="changepass-new-user">
            <span>To Change your password, please use the from below</span>
            <div className="register-new-user-tab">
              <div>
                <span>Current Password </span>
              </div>
              <div>
                <input
                  name="currpassword"
                  onChange={handleChange}
                  value={details.currpassword}
                  type="password"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>New Password </span>
              </div>
              <div>
                <input
                  name="password"
                  onChange={handleChange}
                  value={details.password}
                  type="password"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div>
                <span>Confirm New Password </span>
              </div>
              <div>
                <input
                  name="confpassword"
                  onChange={handleChange}
                  value={details.confpassword}
                  type="password"
                  id="reg-text-field"
                  required
                />
              </div>
            </div>

            <div className="register-new-user-tab">
              <div></div>
              <div>
                <input
                  type="submit"
                  onClick={handlePassSubmit}
                  value="Change Password"
                  id="reg-submit-field"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
