export const API = "https://admin.sabrecwa.com/backend";
export const SeerbitAPI =
  "http://ec2-3-20-158-74.us-east-2.compute.amazonaws.com:8080";

export const Paystack_url = "https://api.paystack.co";

export const NodeServer_API =
  "https://incentive.sabrecwa.com/backend/bookandwin";

// export const NodeServer_API = "http://localhost:4000/bookandwin";

// export const NodeServer_API =
//   "https://m6hsr3z5-4000.uks1.devtunnels.ms/bookandwin";

export const paystack_API =
  "https://api.paystack.co/bank?country=nigeria&use_cursor=true&perPage=100";
